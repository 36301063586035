// MUI
import Container from '@mui/material/Container';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

// Wexp Components
import ResponsiveAppBar from '../components/NavBar';
import Header from '../components/Header';
import coin from '../assets/images/coin.png'
import id from '../assets/images/id.png'
import wallet from '../assets/images/wallet.png'

const Fares = () => {

    const dataWithdrawalCost = [
        {
            fareId: 1,
            nome: "Saque Autorizado",
            unidade: '#',
            premissa: '5,00'
        },
        {
            fareId: 2,
            nome: "Saque não Autorizados",
            unidade: '#',
            premissa: '0,50'
        },
        {
            fareId: 3,
            nome: "Custos por Conta Inativa (<5000 reais/mês)",
            unidade: '',
            premissa: '5,00'
        }
    ]

    const dataKYC = [
        {
            fareId: 1,
            nome: "KYC",
            unidade: 'R$',
            premissa: '2,81'
        },
        {
            fareId: 2,
            nome: "KYC - Usuários PIX",
            unidade: 'R$',
            premissa: '3,69'
        }
    ]

    const dataBanking = [
        {
            fareId: 1,
            nome: "PIX - out",
            unidade: 'R$',
            premissa: '0,27'
        },
        {
            fareId: 2,
            nome: "PIX - in",
            unidade: 'R$',
            premissa: '0,17'
        },
        {
            fareId: 3,
            nome: "PIX - criação de chave email/telefone",
            unidade: 'R$',
            premissa: '1,10'
        },
        {
            fareId: 4,
            nome: "PIX - criação de chave outros",
            unidade: 'R$',
            premissa: '0,10'
        },
        {
            fareId: 5,
            nome: "PIX - Saque/troco",
            unidade: 'R$',
            premissa: '1,00'
        },
        {
            fareId: 6,
            nome: "PIX - Saque/troco adicional AGTEC",
            unidade: 'R$',
            premissa: '1,13'
        },
        {
            fareId: 7,
            nome: "PIX - Saque/troco adicional AGPSS",
            unidade: 'R$',
            premissa: '1,69'
        },
        {
            fareId: 8,
            nome: "PIX - Saque/troco adicional AGTOT",
            unidade: 'R$',
            premissa: '2,25'
        },
        {
            fareId: 9,
            nome: "PIX - QRCode",
            unidade: 'R$',
            premissa: '0,10'
        },
        {
            fareId: 10,
            nome: "PIX - consulta",
            unidade: 'R$',
            premissa: '0,10'
        },
        {
            fareId: 11,
            nome: "Boleto emitido e não liquidado",
            unidade: 'R$',
            premissa: '0,49'
        },
        {
            fareId: 12,
            nome: "Boleto emitido e liquidado digitalmente",
            unidade: 'R$',
            premissa: '1,50'
        },
        {
            fareId: 13,
            nome: "Boleto emitido e liquidado fisicamente",
            unidade: 'R$',
            premissa: '2,90'
        },
        {
            fareId: 14,
            nome: "Custo de TED",
            unidade: 'R$',
            premissa: '2,00'
        },  
    ]
    
    const columns = [
        {
            field: 'fareId',
            headerName: 'ID',
            hide: true
        },
        {
            field: 'nome',
            headerName: 'Nome',
            flex: 2
        },
        {
            field: 'unidade',
            headerName: 'Unidade',
            flex: 1
        },
        {
            field: 'premissa',
            headerName: 'Valor',
            flex: 1
        }
    ]

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header backButton title="Tarifas" />
                <Grid container rowGap={2}>
                    <Grid xs={12}>
                        <Typography variant="h5">Custos Operacionais</Typography>
                    </Grid>
                    <Grid xs={12} id="itemWithdrawalCost">
                        <Grid xs={12} container columnGap={1} sx={{ alignItems: 'center' }}>
                            <img src={coin} alt="" style={{width: '25px', height: '25px'}}/>
                            <Typography variant="h6">Custo Saque</Typography>
                        </Grid>
                        <Grid  
                            xs={12} 
                            style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                            <div style={{ height: 270, width: '100%' }}>
                                <DataGrid
                                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={dataWithdrawalCost}
                                    getRowId={(row) => row.fareId}
                                    columns={columns}
                                    pageSize={3}
                                    rowsPerPageOptions={[3]}
                                />

                            </div>
                        </Grid>
                    </Grid>
                    <Grid xs={12} id="itemKYC">
                        <Grid xs={12} container columnGap={1} sx={{ alignItems: 'center' }}>
                            <img src={id} alt="" style={{width: '25px', height: '25px'}}/>
                            <Typography variant="h6">KYC</Typography>
                        </Grid>
                        <Grid  
                            xs={12} 
                            style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                            <div style={{ height: 220, width: '100%' }}>
                                <DataGrid
                                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={dataKYC}
                                    getRowId={(row) => row.fareId}
                                    columns={columns}
                                    pageSize={2}
                                    rowsPerPageOptions={[2]}
                                />

                            </div>
                        </Grid>
                    </Grid>
                    <Grid xs={12} id="itemBanking">
                        <Grid xs={12} container columnGap={1} sx={{ alignItems: 'center' }}>
                            <img src={wallet} alt="" style={{width: '25px', height: '25px'}}/>
                            <Typography variant="h6">Banking</Typography>
                        </Grid>
                        <Grid  
                            xs={12} 
                            style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                            <div style={{ height: 840, width: '100%' }}>
                                <DataGrid
                                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={dataBanking}
                                    getRowId={(row) => row.fareId}
                                    columns={columns}
                                    pageSize={14}
                                    rowsPerPageOptions={[14]}
                                />

                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                

            </Container>
        </>
    )
}

export default Fares;